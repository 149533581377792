<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="birds">
      <img src="@/assets/images/birds.png" />
    </div>
    <div class="main-content">
      <div class="animation-block-1">
        <div class="text">申请干货</div>
      </div>
      <div class="animation-block-2">
        <img src="@/assets/images/border_top_right.png" />
      </div>
      <div class="animation-block-3"></div>
      <div class="animation-block-4">
        <img src="@/assets/images/qrcode.png" />
      </div>
      <div class="animation-block-5">
        <div class="text">
          扫码添加公众号
          <br />了解更多申请干货
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="other-href" @click="$router.push('/main')">返回</div>
      <div class="com">上海镜台国际教育</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toNext() {
      this.$router.push("/main");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }
  .birds {
    width: 87px;
    height: 44px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 25px;
    margin-top: 88px;
    animation: 8s linear 0s infinite normal both running tempKeyframesForMotion1;
    img {
      height: 100%;
    }
  }
  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 320px;
    height: 486px;
    transform: translate(-50%, -50%);
    .animation-block-1 {
      position: absolute;
      z-index: 999;
      width: 145px;
      height: 66px;
      line-height: 66px;
      top: 6px;
      left: 50%;
      margin-left: -72.5px;
      text-align: center;
      animation: 2s ease 0.8s 1 normal both running fadeInUp;
      .text {
        font-family: Tahoma, Helvetica, Arial;
        font-size: 22px;
        color: rgb(7, 121, 137);
        font-weight: 700;
      }
    }
    .animation-block-2 {
      position: absolute;
      z-index: 99;
      width: 117px;
      height: 62.5px;
      top: 0px;
      left: 120px;
      text-align: left;
      animation: 2s ease 0.6s 1 normal both running rollInLeft;
      img {
        height: 100%;
      }
    }
    .animation-block-3 {
      position: absolute;
      z-index: 99;
      width: 145px;
      height: 62.5px;
      top: 6px;
      left: 50%;
      margin-left: -72.5px;
      text-align: left;
      animation: 2s ease 0.6s 1 normal both running rollInRight;
      background-color: #f4c251;
    }
    .animation-block-4 {
      position: absolute;
      z-index: 99;
      width: 131px;
      height: 131px;
      top: 136.75px;
      left: 50%;
      margin-left: -65px;
      text-align: left;
      animation: 2s ease 0.6s 1 normal both running rollInUp;
      img {
        height: 100%;
      }
    }
    .animation-block-5 {
      position: absolute;
      z-index: 99;
      width: 100%;
      line-height: 66px;
      top: 293.8px;
      left: 50%;
      margin-left: -50%;
      text-align: center;
      animation: 2s ease 0.8s 1 normal both running rollInUp;
      .text {
        font-family: Tahoma, Helvetica, Arial;
        font-size: 14px;
        color: rgb(252, 243, 203);
        font-weight: 700;
        line-height: 1.5;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-image: linear-gradient(
      rgba(18, 121, 135, 0),
      rgba(18, 121, 135, 1)
    );
    padding: 20px 0;
    text-align: center;
    .other-href {
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-decoration-line: underline;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      padding: 8px 16px;
      & + .other-href {
        margin-top: 10px;
      }
    }
    .com {
      margin-top: 10px;
      color: rgb(252, 243, 203);
      text-align: center;
      font-family: Tahoma, Helvetica, Arial;
      font-size: 14px;
      animation: 2s ease 1s 1 normal both running fadeInUp;
    }
  }
}
</style>